import imageTwo from '../../assets/images/1.png'
// import img from '../../assets/images/'

export const slides = [
    {
        name: '-Vijay',
        testimonial: `I've been in the cable
        business for years,
        but this book opened
        my eyes to new
        possibilities. The
        SUCCESS framework
        is a game-changer
        for any cable dealer!`,
        city: 'Supertronix , Banglore',
        image: imageTwo,
        alt: 'Imageone',
    },
    {
        name: '- Mr. Sumit Advani',
        testimonial: `This book is a game-
        changer! It's packed
        with practical
        strategies that
        helped us boost
        profits and gain
        customer loyalty. A
        must-read for
        anyone in the cable
        industry!`,
        city: 'M/s Sanjay Electronics, Mumbai',
        image: imageTwo,
        alt: 'ImageTwo',
    },
    {
        name: '- Priyansh',
        testimonial: `Sudhir and Rahul
        Makhija's book is a
        gold mine of insights.
        Their marketing
        strategies are a game-
        changer for cable
        dealers.
        Highly recommended!`,
        city: 'TechConnect Cables, Indore',
        image: imageTwo,
        alt: 'Imagethree',
    },
]