import React, { useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import Button from "../components/Button";
import Title from "../components/Title/Title";
import Para from "../components/Title/Para";
import { faqs } from "../assets/data/faq";
import axios from "axios";

import bannerImg from "../assets/images/landingimages/bothImg.png";
import serviceBlockImg from "../assets/images/landingimages/standImg.png";
import bookImg from "../assets/images/landingimages/books.png";
import aboutimg from "../assets/images/landingimages/founder.png";
import iconImg from "../assets/images/landingimages/icons.png";


import { slides } from "../assets/data/SlideData";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { FaCheckCircle } from "react-icons/fa";
import { AiFillDashboard } from "react-icons/ai";
import { FaBuromobelexperte } from "react-icons/fa";
import { RiCustomerService2Fill } from "react-icons/ri";
import { FcSalesPerformance } from "react-icons/fc";
import { MdOutlineManageSearch } from "react-icons/md";
import { TbBrandGoogleAnalytics } from "react-icons/tb";

const Home = () => {
  const contactRef = useRef();
  const history = useHistory();

  const initialValues = {
    name: "",
    number: "",
    email: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [valid, setValid] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target; // take name and value from input in destructring
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errorCheck = validate(formValues);
    setFormErrors(errorCheck);
    setIsSubmit(true);
    if (Object.keys(errorCheck).length === 0) {
      const res = await axios.post(
        "https://test23.goodgoodpiggy.in/api/leads/",
        formValues
      );
      if (res.data.status === true) {
        resetInput();
        history.push("/thank-you");
      } else {
        alert("Some Error Happen, Please Try Again");
      }
    }
  };

  const resetInput = () => {
    setFormValues(initialValues);
    setValid(false);
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    //name
    if (!/^[a-z A-Z0-9]+$/.test(values.name) || /^[ ]+$/.test(values.name)) {
      errors.name = "Name is not valid";
    }

    if (!values.name) {
      errors.name = "Name is Required";
    }

    if (values.number) {
      if (values.number.length < 10) {
        errors.number = "Enter atleast 10 digits";
      }
    }

    if (!values.number) {
      errors.number = "Phone Number is Required";
    }

    //email
    if (!values.email) {
      errors.email = "Email is Required";
    }
    if (!regex.test(values.email) && values.email) {
      errors.email = "Email is not valid";
    }

    return errors;
  };

  const scrollhandler = (eleRef) => {
    console.log(eleRef.current);
    window.scrollTo({top: eleRef.current.offsetTop, behavior: 'smooth'});
  };

  const [data1, setData1] = useState(faqs);
  const [show, setShow] = useState(false);

  let data = "";
  const handleShow = (ans) => {
    console.log(ans, "chip idd");
    data = ans;
    console.log(data, "data");

    setShow(!show);
  };

  let settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      {/* //topbar start  */}
      <section className="topbar">
        <div className="container">
          <div className="topbar-area">
            <div className="topbar-email">
              <Link to="/">Cable Dealers Guide</Link>
            </div>
            <div className="topbar-number">
              <Button onClick={() => scrollhandler(contactRef)}>
                GRAB YOUR COPY NOW
              </Button>
            </div>
          </div>
        </div>
      </section>
      {/* // topbar end  */}

      {/* banner start */}
      <section className="banner_wrapper pt-5 pb-5">
        <div className="container">
          <div className="row align-items-center flex-md-row-reverse">
            <div className="col-md-6">
              <img src={bannerImg} alt="" className="img-fluid" />
            </div>
            <div className="col-md-6 my-5 my-md-0 text-md-start">
              <h1 className="banner-title">
                Unlock Success
                <br /> <span>in Cable Industry</span>
              </h1>
              <p className="banner-para">
                A must-read guide for Cable Dealers for Increasing Sales,
                Maximizing Profits and Rising above the Competition.
              </p>
              <div className="mt-4">
                <Link to="">
                  <Button big onClick={() => scrollhandler(contactRef)}>GRAB YOUR COPY NOW!</Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* banner end */}

      {/* features start */}
      <section className="features_wrapper py-5">
        <Title title="Are you facing challanges in your cable business?" />

        <div className="container mt-4 pt-4">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12">
              <div className="card bg-transparent border-0">
                <div className="card-body text-start">
                  <div
                    className="features"
                  >
                    <div className="feature-icon">
                      <span>
                        <AiFillDashboard className="icons" />
                      </span>
                    </div>
                    <div className="feature-text">
                      {/* <h3>Fast Delivery</h3> */}
                      <p>
                        Are you struggling to stand out in the competitive
                        optical fiber cable industry?
                      </p>
                    </div>
                  </div>

                  <div
                    className="features"
                  >
                    <div className="feature-icon">
                      <span>
                        <FcSalesPerformance className="icons" />
                      </span>
                    </div>
                    <div className="feature-text">
                      {/* <h3>Fast Delivery</h3> */}
                      <p>
                        Are your sales and profits falling short of your
                        expectations in the optical fiber cable market?
                      </p>
                    </div>
                  </div>
                  <div
                    className="features"
                  >
                    <div className="feature-icon">
                      <span>
                        <MdOutlineManageSearch className="icons" />
                      </span>
                    </div>
                    <div className="feature-text">
                      {/* <h3>Fast Delivery</h3> */}
                      <p>
                        Are you tired of feeling overwhelmed by the day-to-day
                        operations of your cable dealership?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-4 feature-img">
              <div className="card bg-transparent border-0">
                <div className="card-body text-center">
                  <img
                    src={serviceBlockImg}
                    alt=""
                    className="img-fluid mb-4 service_phone"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="card bg-transparent border-0">
                <div className="card-body text-start">
                  <div
                    className="features"
                  >
                    <div className="feature-icon">
                      <span>
                        <RiCustomerService2Fill className="icons" />
                      </span>
                    </div>
                    <div className="feature-text">
                      {/* <h3>Fast Deliverys</h3> */}
                      <p>
                        Do you find it challenging to attract and retain loyal
                        customers for your cable business?
                      </p>
                    </div>
                  </div>
                  <div
                    className="features"
                  >
                    <div className="feature-icon">
                      <span>
                        <FaBuromobelexperte className="icons" />
                      </span>
                    </div>
                    <div className="feature-text">
                      {/* <h3>Fast Delivery</h3> */}
                      <p>
                        Do you want to discover the secrets to mastering sales
                        in the optical fiber cable industry?
                      </p>
                    </div>
                  </div>
                  <div
                    className="features"
                  >
                    <div className="feature-icon">
                      <span>
                        <TbBrandGoogleAnalytics className="icons" />
                      </span>
                    </div>
                    <div className="feature-text">
                      {/* <h3>Fast Delivery</h3> */}
                      <p>
                        Have you ever wondered how to turn your optical fiber
                        cable shop into a recognized brand?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-4 pt-4">
          <div className="row">
            <div className="col-lg-12 d-flex justify-content-center">
              <p className="feature-heading">
                If your answer to most of the above Question is YES, and if
                you're tempted to start Googling, hold on! The answers you seek
                can't be found there. The only guide that will truly help you
                is..."
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* features end */}

      {/* faqs start */}
      <section id="faq" className="faqs">
        <Title title="Optical Fibre Cable Dealers Guide to Success" />
        <Para para="Written by India’s Only Dealer Growth Experts Sudhir Makhija and Rahul Makhija." />
        {/* <Para para="Here's why this book is a game-changer for your business:"/> */}
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-12 faq-flex">
              {data1.map((faq, id) => {
                return (
                  <div className="accordion-item">
                    <button
                      className="faq-ques accordion-btn"
                      onClick={() => {
                        handleShow(faq.ans);
                      }}
                    >
                      <p style={{ width: "99%" }} className="accordion-caption">
                        {faq.ques}: <span>{faq.ans}</span>
                      </p>
                    </button>

                    {/* <div
                      align="justify"
                      style={{ width: "99%" }}
                      className="accordion-content"
                    >
                      <p>{faq.ans}</p>
                    </div> */}
                  </div>
                );
              })}
            </div>
            <div className="col-lg-12 col-12 d-flex justify-content-center mt-2">
              <Link to="">
                <Button big onClick={() => scrollhandler(contactRef)}>GRAB YOUR COPY NOW!</Button>
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* faqs end */}

      {/* testimonial start */}
      <div className="testimonial-area pt-5 pb-5 bg-gray-3 ml-70 mr-70 bg-gray-3">
        <Title title="Praise from Satisfied Readers" />
        <div className="container mt-4">
          <div className="row">
            <div className="col-lg-10 ml-auto mr-auto">
              <div className="testimonial-active nav-style-1 nav-testi-style ">
                <Slider {...settings}>
                  {slides.map((slide, index) => {
                    return (
                      <div className="swiper-slide" key={index}>
                        <div className="single-testimonial text-center">
                          <img
                            className="img-fluid"
                            src={slide.image}
                            alt={slide.alt}
                            loading="lazy"
                            height="90"
                            width="90"
                          />
                          {/* {ReactHtmlParser(item.testimonial)} */}
                          <p className="w-75 mx-auto">{slide.testimonial}</p>
                          <div className="client-info">
                            <i className="fa fa-quote-left"></i>
                            <h5>{slide.name}</h5>
                            <span>{slide.city}</span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* testimonial end */}

      {/* about start */}

      <section className="services_wrapper py-5">
        <Title title="Unveiling the Treasures Within..." />
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <img src={bookImg} className="img-fluid" />
            </div>
          </div>
          <div className="row pb-5">
            <div className="col-lg-4 col-md-6 col-12 mb-4">
              <div className="bg-white services-card">
                <div className="card-body p-3">
                  <FaCheckCircle className="about-icon" />
                  <p className="about-para">
                    How to make effective marketing strategies in Cable
                    Industry. <b>- Page 14</b>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-4">
              <div className="bg-white text-center services-card">
                <div className="card-body p-3">
                  <FaCheckCircle className="about-icon" />
                  <p className="about-para">
                    How to make customer your fan - All about customer service.
                    <b>- Page 27</b>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-12 mb-4">
              <div className="bg-white text-left services-card">
                {/* <div className="service-image-div"></div> */}
                <div className="card-body p-3">
                  <FaCheckCircle className="about-icon" />
                  <p className="about-para">
                    How to Stand Out in Crowd.<b>- Page 46</b>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* about end */}

      {/* founder start */}
      <section className="about-page-wrapper pt-5">
        <Title title="Meet the Authors" />

        <div className="about-section container">
          <p className="text-center founder-name">Sudhir and Rahul Makhija</p>
          <div className="about-text row">
            <div className="about-quality col-lg-6 col-md-6 col-12">
              <div className="about-quality-1">
                <h4>
                  India’s Leading Cable Dealer Growth Expert & Director, M-Core
                  Industries (P) Ltd.
                </h4>
                <p>
                  With over 18 Yrs. of experience, <b>Sudhir</b> conducted
                  in-depth research, traveling to 27 cities within 6 months, to
                  gain a comprehensive understanding of the entire Cable
                  Industry. Getting Insights from Dealers & Distributors across
                  India.
                </p>
                <p>
                  <b>Rahul</b>, with over 14 years of experience is a modern &
                  Innovative leader who constantly seeks new ways to improve the
                  Cable Manufacturing Industry.
                </p>
                <p>
                  Each chapter of the book is self-contained, focusing on
                  specific challenge & its comprehensive solution and the
                  Complete <b>Success Framework</b> given in the book is a
                  Roadmap for all the Dealers in the Cable Industry.
                </p>
              </div>
              <div className="about-quality-2"></div>
              {/* <div className="bn">
              <a href="tel:9101141251500" title="Toll Free Number">
                <Button>Call Now</Button>
              </a>
            </div> */}
            </div>
            <div className="about-image text-center col-lg-6 col-md-6 col-12">
              <img
                src={aboutimg}
                alt="about-image img-fluid"
                height="100"
                width="100"
              />
            </div>
          </div>
        </div>
      </section>
      {/* founder end */}

      <div className="contact-form-section py-5" ref={contactRef}>
        <div
          className="contact-auth-section col-lg-6 col-md-6 ml-auto mr-auto"
        >
          <div className="contact-authentication">
            <img src={iconImg} className="img-fluid" />
            <h2 className="text-center mb-4">
              Limited time, a FREE copy awaits!
            </h2>
            <form method="POST">
              <div className="form-row">
                <div className="form-group col-12 mb-2">
                  <label>
                    <b>Tell us, where should we deliver it?</b>
                  </label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Your Email"
                    value={formValues.email}
                    onChange={handleChange}
                  />
                  <small className="text-red">{formErrors.email}</small>
                </div>
                <div className="form-group col-md-6 mb-2">
                  <label>
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Your Name"
                    name="name"
                    onChange={handleChange}
                    value={formValues.name}
                  />
                  <small className="text-red">{formErrors.name}</small>
                </div>
                <div className="form-group col-md-6 mb-2">
                  <label>
                    Contact
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="10 Digit Mobile Number"
                    name="number"
                    value={formValues.number}
                    onChange={handleChange}
                  />
                  <small className="text-red">{formErrors.number}</small>
                </div>

                <div className="form-group col-12 form-btn">
                  <Button big type="submit" onClick={handleSubmit}>
                    Download your copy now
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
