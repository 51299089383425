import React from "react";
import bothImg from "../assets/images/landingimages/bothImg.png";
import Title from "../components/Title/Title";
import Button from "../components/Button";
import pdf from "../assets/images/landingimages/Cable-Dealers-Guide.pdf";

const Thanks = () => {
  return (
    <>
      <section id="" className="thankyou-box">
        <div className="thank-you">
          <div className="container">
            <div className="row justify-content-center text-center">
              <div className="col-md-12 col-lg-12 pr-md-0">
                {/* <h2 className="thanku-ttl">Thank you!</h2> */}
                <Title title="Thank You" />
              </div>
              <div className="col-md-12 col-lg-6 pl-md-0 text-center">
                <img src={bothImg} className="img img-fluid"/>
              </div>
              <div className="col-md-12 col-lg-12 pr-md-0">
                <p className="padtop20">
                  We appreciate your interest in Sudhir & Rahul Makhija's book <br/>"Optical Fibre Cable Dealer's Guide to Success".
                </p>
                <p className="small-top">
                  {" "}
                  You can download your free copy of ebook
                  <br className="d-none d-sm-block" /> by clicking the button
                  below.
                  <br />
                  <br />
                </p>
                <a href={pdf} rel="noopener noreferrer" target="_blank">
                  <Button>Download Book NOW</Button>
                </a>
                <br />
                <p className="happy">Happy Reading!</p>
                {/* <p>Do you want a Physical Copy of this Book, Delivered at Your Doorstep</p> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Thanks;
